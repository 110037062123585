.loginView--PlJga {
  display: block;
}
.loginViewWrapper--1tUyK {
  margin: 0px auto;
  max-width: 350px;
}
.loginViewFields--2el8h {
  margin-bottom: 21px;
}
.loginViewRememberMe--2GD1_ {
  color: rgba(84,84,84,1);
  font-size: 0.9375rem;
}
.loginViewLinks--2vPt_ {
  padding: 24px 0 48px;
  text-align: center;
  font-size: 0.8125rem;
}
.loginViewLinks--2vPt_ a {
  color: #8f93c4;
}
.loginFacebookButton--2vxuR {
  height: 50px;
  width: 100%;
  background: #4267b2;
  border: 0px;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 1.0625rem;
  font-weight: bold;
}
.loginFacebookButton--2vxuR .fa-facebook {
  margin-right: 16px;
}
.loginHeaderSeparator--q9v53 {
  text-align: center;
  margin-top: 32px;
  margin-bottom: 32px;
  color: rgba(158,158,158,1);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.loginSeparator--8sku6 {
  height: 1px;
  background: #e2e5f7;
  width: 45%;
  margin: 8px 0px;
}
.loginSeparatorText--3iDtS {
  width: 10%;
  font-size: 0.875rem;
  line-height: 17px;
  margin: 0px;
}
@media (max-height: 840px) {
  .loginViewLinks--2vPt_ {
    padding: 16px 0 24px;
    text-align: center;
  }
}
