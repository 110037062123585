.userItem--3ZOIG {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e2e5f7;
}
.userItem--3ZOIG .email--1_0dd {
  padding: 16px;
  width: 20%;
  border-right: 1px solid #e2e5f7;
}
.userItem--3ZOIG .firstName--2OQwR,
.userItem--3ZOIG .lastName--nM8WS {
  padding: 16px;
  width: 20%;
  border-right: 1px solid #e2e5f7;
}
.userItem--3ZOIG .action--2HRuR {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.deleteButton--C3bG3 {
  background: #d0021b;
  border: 0px;
  border-radius: 8px;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
  height: 38px;
  line-height: 36px;
  padding: 0px 12px;
  margin: 8px;
  float: right;
  cursor: pointer;
}
