.faqView--16ie5 {
  padding: 0px 16px;
}
.faqTitle--HCH36 {
  font-size: 2.1875rem;
  font-weight: normal;
  margin-bottom: 0px;
}
.faqSeparator--1ZZ_l {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e2e5f7;
  margin: 42px 0 16px 0px;
  padding: 0;
}
.faqMainBox--3e2Ut {
  margin: 30px auto 30px auto;
  max-width: 1000px;
  border: 1px solid #e2e5f7;
  min-height: 500px;
}
.faqHeader--1OXcE {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e2e5f7;
  text-transform: uppercase;
  font-size: 0.6875rem;
  color: rgba(84,84,84,1);
}
.faqHeader--1OXcE .title--3kbdv {
  padding: 4px 16px;
  width: 20%;
  border-right: 1px solid #e2e5f7;
}
.faqHeader--1OXcE .content--19OaE {
  padding: 4px 16px;
  width: 50%;
  border-right: 1px solid #e2e5f7;
}
.faqHeader--1OXcE .action--2YCXU {
  padding: 4px 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.faqList--2Udz- {
  margin: 0px;
}
.faqHeaderActions--D1EWL {
  min-height: 38px;
  margin: 0px auto;
  margin-bottom: -12px;
  max-width: 1000px;
  text-align: right;
}
.faqHeaderActions--D1EWL .addButton--39C1c {
  min-height: 38px;
  padding: 0px 16px;
  font-size: 0.875rem;
  min-width: 140px;
}
.faqAddView--3MhZB {
  max-width: 380px;
  min-height: 300px;
  margin: 16px auto;
  border-radius: 3px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #fff;
  -webkit-box-shadow: 0 5px 15px 0 rgba(0,0,0,0.38);
          box-shadow: 0 5px 15px 0 rgba(0,0,0,0.38);
  padding-bottom: 24px;
  padding: 24px;
}
.addDialogContent--u1QO8 {
  padding: 16px;
}
.addTextareaWrapper--21Z6J {
  position: relative;
  border-radius: 5px;
  border: 1px solid #e2e5f7;
  background: rgba(226,229,247,0.25);
  margin-top: 16px;
}
.addInputWrapper--1DAxT {
  position: relative;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #e2e5f7;
  background: rgba(226,229,247,0.25);
  margin-top: 16px;
}
.dropdown--2hjjH {
  position: relative;
  width: 100%;
  background: rgba(226,229,247,0.25);
}
.addInputWrapper--1DAxT:first-child {
  margin-top: 0px;
}
.addInputTitle--1qODl {
  font-size: 11px;
  position: absolute;
  z-index: 0;
  color: rgba(158,158,158,1);
  top: 10px;
  left: 16px;
}
.adminsModalCancel--36Cl5 {
  background: #d4d4d5;
  color: #333;
}
.addInputError--4pJrE {
  margin-top: 8px;
}
.addInput--eIxdl {
  width: 100%;
  margin-bottom: 12px;
  background: transparent;
  border: 0px;
  position: relative;
  padding: 12px 16px 0px 16px;
  font-size: 1.0625rem;
}
textarea.addInput--eIxdl {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 180px;
  border: 0px;
  border-radius: 5px;
  margin-top: 8px;
  resize: none;
  padding: 16px;
  font-size: 1rem;
}
.faqModalButton--3jE79 {
  margin-top: 16px;
}
.faqHeaderText--_FGFU {
  font-size: 1.1875rem;
  color: #212121;
  font-weight: bold;
  text-align: center;
  margin: 0px auto;
  max-width: 260px;
}
.error--3Ohg4 {
  color: #d0021b;
  font-size: 0.75rem;
  margin: 0px;
  margin-top: -12px;
}
