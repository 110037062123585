.alertModalView--dDCWg {
  width: 320px;
  margin: 32px auto;
  border-radius: 3px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #fff;
  -webkit-box-shadow: 0 5px 15px 0 rgba(0,0,0,0.38);
          box-shadow: 0 5px 15px 0 rgba(0,0,0,0.38);
  padding: 20px;
}
.alertModalTitleText--28kBa {
  font-size: 1.5625rem;
  margin-bottom: 10px;
  font-weight: bold;
}
.alertModalTextContent--3hJUy {
  margin: 0px;
  font-size: 1rem;
  margin-bottom: 16px;
  color: #757575;
}
.alertModalFooter--1WQw2 {
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.alertModalCancel--1Fc6D,
.alertModalConfirm--17BdG {
  min-height: 0;
  padding: 0.575rem 16px;
}
.alertModalCancel--1Fc6D {
  color: #757575;
}
