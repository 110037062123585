.pushNotificationsView--1O85q {
  padding: 0px 16px;
}
.pushNotificationsTitle--5-nya {
  font-size: 2.1875rem;
  font-weight: normal;
  margin-bottom: 0px;
}
.pushNotificationsSeparator--Zdo5A {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e2e5f7;
  margin: 42px 0 16px 0px;
  padding: 0;
}
.pushNotificationsMainBox--1NdSe {
  margin: 30px auto 30px auto;
  max-width: 800px;
  border: 1px solid #e2e5f7;
  min-height: 400px;
  padding-top: 16px;
}
.pushAddView--2jOVT {
  max-width: 380px;
  min-height: 300px;
  margin: 16px auto;
  border-radius: 3px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #fff;
  -webkit-box-shadow: 0 5px 15px 0 rgba(0,0,0,0.38);
          box-shadow: 0 5px 15px 0 rgba(0,0,0,0.38);
  padding-bottom: 24px;
  padding: 24px;
}
.addDialogContent--1jzmh {
  padding: 16px;
}
.addTextareaWrapper--2BeU3 {
  position: relative;
  border-radius: 5px;
  border: 1px solid #e2e5f7;
  background: rgba(226,229,247,0.25);
  margin-top: 16px;
}
.addInputWrapper--29uxO {
  position: relative;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #e2e5f7;
  background: rgba(226,229,247,0.25);
  margin-top: 16px;
}
.addInputWrapper--29uxO:first-child {
  margin-top: 0px;
}
.addInputTitle--1fvvf {
  font-size: 11px;
  position: absolute;
  z-index: 0;
  color: rgba(158,158,158,1);
  top: 10px;
  left: 16px;
}
.adminsModalCancel--1ZJsy {
  background: #d4d4d5;
  color: #333;
}
.addInputError--23wbT {
  margin-top: 8px;
}
.addInput--2cn21 {
  width: 100%;
  margin-bottom: 12px;
  background: transparent;
  border: 0px;
  position: relative;
  padding: 12px 16px 0px 16px;
  font-size: 1.0625rem;
}
textarea.addInput--2cn21 {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 180px;
  border: 0px;
  border-radius: 5px;
  margin-top: 8px;
  resize: none;
  padding: 16px;
  font-size: 1rem;
}
.pushModalButton--1_xRl {
  margin-top: 16px;
}
.pushHeaderText--1L0R3 {
  font-size: 1.1875rem;
  color: #212121;
  font-weight: bold;
  text-align: center;
  margin: 0px auto;
  max-width: 260px;
}
