.sideBarWrapper--38JLD {
  position: relative;
  width: 80px;
  height: 100vh;
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
}
.sideBar--3XgK6 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 14rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: all ease-in-out 0.1s;
  -o-transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
  z-index: 998;
  -webkit-box-shadow: 5px 0px 13px 0px rgba(0,0,0,0.45);
          box-shadow: 5px 0px 13px 0px rgba(0,0,0,0.45);
}
.sideBar--3XgK6 * {
  -webkit-transition: all ease-in-out 0.1s !important;
  -o-transition: all ease-in-out 0.1s !important;
  transition: all ease-in-out 0.1s !important;
}
.sideBar--3XgK6.sideBarCollapsed--3OVbw {
  -webkit-box-shadow: unset;
          box-shadow: unset;
  width: 80px;
}
.sideBarLogo--LEwBo {
  -webkit-transition: all ease-in-out 0.1s;
  -o-transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
  width: 51px;
  height: 14px;
}
.sideBarScroll--HP9h6 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  background: #2c3134;
  color: #999;
}
.sideBarTop--3r9__ {
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 4rem;
  color: #8f93c4;
  text-align: center;
  font-weight: bold;
  padding: 24px 0px;
  font-size: 0.75rem;
}
.sideBarUser--2d4qd {
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  position: relative;
}
.sideBarUser--2d4qd:hover .sideBarToolTip--3uC_v {
  display: block;
}
.sideBarItemActive--3eyL- .sideBarUserLink--3CSSN {
  background: rgba(0,0,0,0.2);
  border-left: 5px solid #8f93c4;
}
.sideBarUserLink--3CSSN {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  border-left: 5px solid #2c3134;
  color: #999;
}
.sideBarUserAvatar--3uraV {
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  width: 24px;
  height: 24px;
  margin: 16px 28px 16px 23px;
  border-radius: 100px 100px;
  background: #fff;
  display: block;
}
.sideBarUserAvatarPic--3k-e7 {
  width: 100%;
  height: 100%;
  border-radius: 100px 100px;
}
.sideBarUserText--243tc {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding-right: 10px;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.014285714285714em;
  -webkit-transition-delay: 0.2s;
       -o-transition-delay: 0.2s;
          transition-delay: 0.2s;
  overflow: hidden;
  white-space: nowrap;
  display: none !important;
}
.sideBarUserWelcome--2m1Mj {
  font-size: 0.75rem;
  color: #626262;
}
.sideBarUserName--AKGSG {
  font-weight: 500;
  font-size: 0.875rem;
}
.sideBarMain--1TKJe {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  list-style: none;
  margin: 0;
  padding: 0;
}
.sideBarItem--223J7 {
  border-bottom: 1px solid rgba(0,0,0,0.2);
  position: relative;
}
.sideBarItem--223J7:hover .sideBarToolTip--3uC_v {
  display: block;
}
.sideBarItemLink--XP41J {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  border-left: 5px solid #2c3134;
  color: #999;
}
.sideBarItemActive--3eyL- .sideBarItemLink--XP41J {
  background: rgba(0,0,0,0.2);
  border-color: #8f93c4;
}
.sideBarItemIcon--3X0oz {
  cursor: pointer;
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  display: block;
  width: 32px;
  padding: 16px 25px 16px 18px;
  text-align: center;
}
.sideBarItemText--2br51 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding-right: 10px;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.014285714285714em;
  -webkit-transition-delay: 0.2s;
       -o-transition-delay: 0.2s;
          transition-delay: 0.2s;
  overflow: hidden;
  white-space: nowrap;
  display: none !important;
}
.sideBarBottom--3jOXu {
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  height: 50px;
  text-align: left;
  font-size: 0.75rem;
  color: #626262;
  padding-top: 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 30px;
}
.sideBarCollapsed--3OVbw .sideBarBottom--3jOXu {
  color: #999;
  font-size: 0.375rem;
  text-align: center;
  padding-left: 0px;
}
.sideBarCollapse--2__8Y {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  right: -14px;
  top: 0;
  width: 21px;
  height: 100%;
  margin: 0;
  padding: 0 0 0 7px;
  border: none;
  outline: none;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
}
@media only screen and (hover: hover) {
  .sideBarCollapse--2__8Y {
    opacity: 0;
    -webkit-transition: opacity 400ms ease-in-out;
    -o-transition: opacity 400ms ease-in-out;
    transition: opacity 400ms ease-in-out;
  }
  .sideBarCollapse--2__8Y:hover,
  .sideBarCollapse--2__8Y:focus {
    opacity: 1;
  }
}
.sideBarCollapsed--3OVbw .sideBarCollapseIcon--19cLL {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.sideBarBottomList--1fT3d {
  visibility: visible;
}
.sideBarLangageSwitch--3nz5r {
  padding: 15px 0px;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.75rem;
  text-align: left;
  text-indent: 30px;
}
.sideBarCollapsed--3OVbw .sideBarLangageSwitch--3nz5r {
  width: 100%;
  text-align: center;
  margin: 0px;
  text-indent: unset;
}
.sideBarCompany--2O1K3 {
  border-top: 1px solid rgba(0,0,0,0.2);
  border-bottom: 1px solid rgba(0,0,0,0.2);
  cursor: pointer;
  line-height: 1.4;
  text-align: center;
}
.sideBarCompanyImage--1YJJI {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  margin: 24px 20px 4px 20px;
}
.sideBarCompanyName--WcMYC {
  font-size: 0.6875rem;
  letter-spacing: 0.014285714285714em;
  -webkit-transition-delay: 0.2s;
       -o-transition-delay: 0.2s;
          transition-delay: 0.2s;
  margin: 0px 4px 8px 4px;
  text-align: center;
}
.sideBarOpenCompanyIcon--3MzQF {
  margin-left: auto;
  margin-right: 10px;
}
.sideBarCollapsed--3OVbw .sideBarOpenCompanyIcon--3MzQF {
  display: none;
}
.sideBarCompanyMenu--2NS5C {
  position: absolute;
  left: 14rem;
  top: 64px;
  width: 14rem;
  border: 1px solid #e2e5f7;
  border-radius: 3px;
  background-color: #fff;
  z-index: 100;
  color: #949ca9;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}
.sideBarCollapsed--3OVbw .sideBarCompanyMenu--2NS5C {
  left: 80px;
}
.sideBarCompanyMenuTitle--Ad9Gi {
  font-size: 0.6875rem;
  font-weight: bold;
  border-bottom: 1px solid #e2e5f7;
  padding: 12px 20px;
  color: rgba(84,84,84,1);
  text-transform: uppercase;
}
.sideBarCompanyMenuItems--1V4Q6 {
  visibility: visible;
}
.sideBarCompanyMenuItem--3Ptcz {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  padding: 5px 20px;
}
.sideBarCompanyMenuItem--3Ptcz:last-child {
  padding-bottom: 15px;
}
.sideBarCompanyMenuCurrentItem--Rbjri {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  padding: 5px 20px;
  background-color: #f4f4f4;
}
.sideBarCompanyMenuCurrentItem--Rbjri:last-child {
  padding-bottom: 15px;
}
.sideBarCompanyMenuIcon--3b9-7 {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
}
.sideBarCompanyMenuName--3vwPR {
  font-size: 0.875rem;
  margin-left: 10px;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.sideBarCompanyMenuCurrentItem--Rbjri .sideBarCompanyMenuName--3vwPR {
  color: rgba(84,84,84,1);
}
.sideBarCompanyMenuCreateCompany--2Srja {
  border-top: 1px solid #e2e5f7;
  font-size: 0.875rem;
  padding: 10px 20px;
  cursor: pointer;
}
.sideBarCompanyMenuCreateCompanyLink--1Ht0a {
  color: #949ca9;
}
.sideBarToolTip--3uC_v {
  position: absolute;
  left: 70px;
  top: 14px;
  background: #999;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  padding: 8px;
  border-radius: 5px;
  display: none;
  -webkit-animation: moveUp--1bWJh 0.3s;
          animation: moveUp--1bWJh 0.3s;
}
.sideBarToolTipArrow--1fvj3:before {
  content: ' ';
  position: absolute;
  width: 13px;
  height: 13px;
  background: #999;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  left: -6px;
  top: 10px;
}
.sideBarToolTipText--2Tdc9 {
  color: #333;
  font-size: 0.75rem;
  vertical-align: middle;
  line-height: 15px;
  font-weight: 600;
  display: block;
  white-space: nowrap;
}
@media (max-height: 900px) {
  .sideBarTop--3r9__ {
    height: 3rem;
    padding: 16px 14px 0;
  }
  .sideBarCompanyImage--1YJJI {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    -webkit-box-flex: 0;
    -webkit-flex: none;
        -ms-flex: none;
            flex: none;
    margin: 16px 26px 4px 24px;
  }
  .sideBarItemIcon--3X0oz {
    padding: 12px 25px 12px 18px;
  }
  .sideBarToolTip--3uC_v {
    top: 10px;
    -webkit-animation: moveUpSmall--1LS5Q 0.3s;
            animation: moveUpSmall--1LS5Q 0.3s;
  }
}
.cursorPointer--nDc5a {
  cursor: pointer;
}
.chatActiveBackground--38Iv- {
  background-color: #7f56ac;
  border-color: #7f56ac;
}
.colorWhite--39o1A {
  color: #fff;
}
.newChatBubble--rnTz- {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 4px 0 #5e3e81;
          box-shadow: 0 2px 4px 0 #5e3e81;
  background-color: #c674da;
  margin: 6px;
  left: 50px;
}
@media print {
  .sideBarWrapper--38JLD {
    display: none !important;
  }
}
@-webkit-keyframes moveUp--1bWJh {
  from {
    top: 24px;
    opacity: 0;
  }
  to {
    top: 14px;
    opacity: 1;
  }
}
@keyframes moveUp--1bWJh {
  from {
    top: 24px;
    opacity: 0;
  }
  to {
    top: 14px;
    opacity: 1;
  }
}
@-webkit-keyframes moveUpSmall--1LS5Q {
  from {
    top: 24px;
    opacity: 0;
  }
  to {
    top: 10px;
    opacity: 1;
  }
}
@keyframes moveUpSmall--1LS5Q {
  from {
    top: 24px;
    opacity: 0;
  }
  to {
    top: 10px;
    opacity: 1;
  }
}
