.authDialogContent--3_DKC {
  padding: 48px 42px 24px;
}
.authDialogSeparator--JAW7W {
  border-top: 1px solid #e2e5f7;
}
.authTitle--1j3oq {
  margin-top: 0.25em;
  font-weight: 900;
  font-size: 1.375rem;
  letter-spacing: 0.005454545454545em;
}
.authText--1-0gF {
  color: rgba(84,84,84,1);
  line-height: 1.4em;
  font-size: 0.9375rem;
  white-space: pre-line;
  text-align: center;
  margin-top: 0px;
}
.authInput--QYFPT {
  width: 100%;
  margin-bottom: 12px;
  background: transparent;
  border: 0px;
  padding: 24px 16px 0px 16px;
  font-size: 1.1875rem;
  position: relative;
}
.small--19sQd .authInput--QYFPT {
  min-width: 160px;
  padding: 8px 8px 0px 8px;
  font-size: 0.875rem;
}
.small--19sQd .authInputTitle--372Km {
  font-size: 11px;
  position: absolute;
  z-index: 0;
  color: rgba(158,158,158,1);
  top: 6px;
  left: 8px;
}
.hidden--nS9Kw {
  display: none !important;
}
.passwordResetFields--2dXHJ {
  margin: 24px 0 10px;
}
.passwordResetLinks--17RLz,
.registerLinks--1wJTC,
.selectRoleLinks--14Wif {
  margin-top: 32px;
  padding: 20px 0;
  text-align: center;
  font-size: 1.0625rem;
}
.selectRoleLinks--14Wif {
  color: #8f93c4;
  cursor: pointer;
}
.passwordResetLinks--17RLz span,
.selectRoleLinks--14Wif span,
.passwordResetLinks--17RLz svg,
.selectRoleLinks--14Wif svg,
.passwordResetLinks--17RLz a,
.selectRoleLinks--14Wif a {
  display: inline-block;
  line-height: 24px;
  vertical-align: bottom;
}
.authBackArrow--1GNFF {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: 0;
  top: -32px;
  font-size: 0.875rem;
  color: #fff;
  line-height: 24px;
}
.authBackArrow--1GNFF span {
  margin-left: 8px;
  font-weight: $boldFont;
}
.authBackButton--2pFBB {
  background: transparent;
  color: #8f93c4;
  border: 0px;
  cursor: pointer;
}
.registerInfoText--srgIZ {
  font-size: 0.8125rem;
  color: #949ca9;
  margin-top: 0px;
  margin-bottom: 12px;
}
.disabledButton--1ed6P {
  background-color: rgba(0,0,0,0.08);
  color: #dbdbdb;
  font-size: 1.0625rem;
  cursor: default;
}
.textLeft--3Zcji {
  text-align: left;
}
.centerText--2-zem {
  text-align: center;
}
.photoText--3RsO7 {
  font-size: 1.0625rem;
  color: rgba(84,84,84,1);
}
.photoUploadWrapper--qnSLC,
.avatarView--3Hg4a {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  background-color: #fff;
  margin: 0px auto;
  margin-bottom: 32px;
  -webkit-box-shadow: 0 8px 28px 0 rgba(0,0,0,0.08);
          box-shadow: 0 8px 28px 0 rgba(0,0,0,0.08);
}
.completeContent--rSaim .photoUploadWrapper--qnSLC {
  overflow: hidden;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.photoUploadWrapper--qnSLC {
  cursor: pointer;
  position: relative;
}
.photoUploadWrapper--qnSLC .photoUploadContent--1hADk {
  z-index: 2;
}
.photoUploadWrapper--qnSLC svg {
  margin-top: 38px;
}
.photoUploadWrapper--qnSLC .addPhotoText--tDMI3 {
  color: #c2c2c2;
  font-size: 0.75rem;
  margin-top: 0px;
  max-width: 80px;
  margin: 0px auto;
}
.photoUploadWrapper--qnSLC img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.dropZone--glBuS {
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
}
.maybeButton--2-Ara,
.removeButton--3Q5vn {
  color: #8f93c4;
  font-size: 1.0625rem;
  cursor: pointer;
  line-height: 1.5em;
  display: inline-block;
  padding: 0.5em;
}
.maybeButton--2-Ara {
  background: transparent;
  border: 0px;
}
.maybeButton--2-Ara * {
  fill: #8f93c4;
}
.userOtherType--3DPHl {
  display: block;
  text-align: center;
  cursor: pointer;
  font-size: 0.8125rem;
  color: #8f93c4;
  margin-top: 8px;
  font-weight: bold;
}
.uploadContentWrapper--1BJYT .actionContent--2XA_F {
  display: block;
}
.uploadContentWrapper--1BJYT .completeContent--rSaim {
  display: none;
}
.uploadContentWrapper--1BJYT .completeContent--rSaim .primaryButton--ObdgS {
  margin-top: 32px;
  margin-bottom: 16px;
}
.uploadContentWrapper--1BJYT.active--1g_-8 .actionContent--2XA_F {
  display: none;
}
.uploadContentWrapper--1BJYT.active--1g_-8 .completeContent--rSaim {
  display: block;
}
.selectRoleContent--ekDYE {
  padding-top: 32px;
}
.selectRoleContent--ekDYE .photoUploadWrapper--qnSLC {
  cursor: default;
}
.selectRoleContent--ekDYE .userName--1WPl9 {
  font-weight: 900;
  font-size: 1.375rem;
  letter-spacing: 0.005454545454545em;
  color: rgba(158,158,158,1);
  margin-top: 0px;
}
.selectRoleContent--ekDYE .authTitle--1j3oq {
  margin-bottom: 45px;
}
.selectRoleContent--ekDYE .photoUploadWrapper--qnSLC.hidden--nS9Kw {
  display: none;
}
.createCompanyView--10OYb .subTitle--1i-B5 {
  text-transform: uppercase;
  margin-bottom: 0.5em;
  margin-top: 1.5em;
}
.createCompanyView--10OYb .infoText--2A9yl {
  color: rgba(158,158,158,1);
  font-size: 0.8125rem;
  margin-bottom: 24px;
}
.createCompanyView--10OYb .photoUploadWrapper--qnSLC {
  margin-top: 30px;
  width: 142px;
  height: 65px;
  border-radius: 15px;
  border: dashed 1px #8f93c4;
  position: relative;
  text-align: center;
}
.createCompanyView--10OYb .photoUploadWrapper--qnSLC p {
  z-index: 1;
  width: 100%;
  position: absolute;
  font-size: 2.25rem;
  text-transform: uppercase;
  max-width: 100%;
  color: #dbdbdb;
  top: 50%;
  margin-top: -1.375rem;
  font-weight: bold;
}
.createCompanyView--10OYb .photoUploadWrapper--qnSLC .addIcon--3WFfn {
  width: 22px;
  height: 22px;
  position: absolute;
  background: #fff;
  z-index: 1;
  bottom: -11px;
  border: solid 1px #8f93c4;
  border-radius: 12px;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0,0,0,0.07);
          box-shadow: 0 2px 8px 0 rgba(0,0,0,0.07);
  left: 60px;
  color: #8f93c4;
  font-size: 1.25rem;
  line-height: 20px;
}
.createCompanyView--10OYb .photoUploadedWrapper--2CtJ6 {
  text-align: center;
}
.createCompanyView--10OYb .photoUploadedWrapper--2CtJ6 .uploadedPhoto--1z1dl {
  width: 180px;
  height: 114px;
  border-radius: 15px;
  background-color: #fff;
  margin: 0px auto;
  margin-bottom: 32px;
  margin-top: 30px;
  -webkit-box-shadow: 0 10px 24px 0 rgba(0,0,0,0.05);
          box-shadow: 0 10px 24px 0 rgba(0,0,0,0.05);
  text-align: center;
  position: relative;
  overflow: hidden;
}
.createCompanyView--10OYb .photoUploadedWrapper--2CtJ6 .uploadedPhoto--1z1dl img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.createCompanyView--10OYb .photoUploadedWrapper--2CtJ6 .removeButton--3Q5vn {
  width: 100%;
  padding: 0px;
}
.createCompanyView--10OYb .photoInfoWrapper--CYv_o .photoUploadWrapper--qnSLC {
  display: block;
}
.createCompanyView--10OYb .photoInfoWrapper--CYv_o .photoUploadedWrapper--2CtJ6 {
  display: none;
}
.createCompanyView--10OYb .photoInfoWrapper--CYv_o.active--1g_-8 .photoUploadWrapper--qnSLC {
  display: none;
}
.createCompanyView--10OYb .photoInfoWrapper--CYv_o.active--1g_-8 .photoUploadedWrapper--2CtJ6 {
  display: block;
}
.authWhiteBox--1jLbh {
  width: 400px;
  margin: 0px auto;
  padding-top: 32px;
  background: #fff;
  border-radius: 24px;
  min-height: 512px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  margin-bottom: 48px;
}
.authHeaderLogoContent--1k-YG {
  margin: 0px auto 64px auto;
}
.authHeaderLogoContent--1k-YG img {
  width: 60px;
  margin: 0px auto;
  display: block;
}
.authHeaderLogo--3ggNv {
  display: block;
  margin: 0px auto;
}
.authHeaderTitle--sGwQ0 {
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  margin-top: 8px;
  color: #fff;
}
.authHeaderSvgIcon--2iaeo {
  display: block;
  margin: 0px auto;
}
.authBoxHeaderText--FryKa {
  font-size: 1.625rem;
  color: #212121;
  font-weight: bold;
  text-align: center;
  margin: 0px auto;
  max-width: 260px;
}
.authTermsText--1sbRF {
  font-size: 0.8125rem;
  text-align: center;
  margin: 48px auto;
  max-width: 240px;
}
.authTermsText--1sbRF span {
  color: #8f93c4;
}
.authValidationText--1P-Nn {
  font-size: 0.8125rem;
  text-align: center;
  max-width: 280px;
  margin: 8px auto;
}
.authValidationText--1P-Nn span {
  color: #8f93c4;
}
.authBackLink--2UOvL {
  padding: 0px;
  font-size: 0.8125rem;
  font-weight: bold;
  text-align: center;
  color: #fff !important;
  display: block;
  margin: 0px auto;
  cursor: pointer;
}
.authFooterText--IR-8F {
  color: #fff;
  font-size: 0.8125rem;
  text-align: center;
  margin-top: 48px;
  margin-bottom: 0px;
}
.authContinueButton--3EHAg {
  margin-top: 16px;
}
.authContinueButton--3EHAg[disabled] {
  opacity: 0.3;
}
.authInputWrapper--2pfE5 {
  position: relative;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #e2e5f7;
  background: rgba(226,229,247,0.25);
  margin-top: 16px;
}
.authInputWrapper--2pfE5.small--19sQd {
  height: 48px;
  margin-top: 0;
}
.authInputWrapper--2pfE5:first-child {
  margin-top: 0px;
}
.authInputTitle--372Km {
  font-size: 11px;
  position: absolute;
  z-index: 0;
  color: rgba(158,158,158,1);
  top: 10px;
  left: 16px;
}
.authValidationIcon--e0DYX {
  margin-top: 64px;
}
.authPager--1psql {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.clickable--21ieJ {
  cursor: pointer;
}
.authExplanation--3VUOs {
  font-size: 0.8125rem;
  color: rgba(158,158,158,1);
  text-align: center;
  margin-top: 16px;
}
.authShowPasswordContent--2_ma7 {
  position: absolute;
  right: 8px;
  height: 24px;
  top: 18px;
  background: transparent;
  border: 0px;
}
.authShowPasswordIcon--1P8-C {
  cursor: pointer;
}
.authInputError--371wv {
  margin-top: 8px;
}
.authIndustryContent--1kZvN {
  margin-top: 8px;
  margin-bottom: 16px;
}
.authDropDown--16cS4 {
  width: 100%;
}
.authDropDownBox--YF9Hi {
  border-radius: 8px !important;
}
.authDropDownBox--YF9Hi button {
  background: transparent;
}
.authDropDownContent--20oPw {
  position: absolute;
}
.authCenterText--1iJbA {
  font-size: 0.8125rem;
  max-width: 280px;
  margin: 8px auto;
  text-align: center;
}
.authPhoneValidationInputWrapper--3ljHT {
  text-align: center;
  margin: 0px auto 32px auto;
}
.authPhoneValidationInput--3jsi2 {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  background: #f3f3f3;
  text-align: center;
  font-size: 2rem;
  color: #212121;
  margin: 0px 4px;
  border: 0px;
}
.authResendButton--8VBe5 {
  margin-top: 32px;
  text-align: center;
  color: #8f93c4;
  text-transform: uppercase;
  font-size: 0.875rem;
  cursor: pointer;
}
.authResendDisabled--FegIO {
  color: rgba(84,84,84,1);
  cursor: default;
}
.authValidationSpinner--1FOmV {
  display: block;
  margin: 0px auto 32px auto;
}
.authInputValidationIcon--1nF8- {
  position: absolute;
  right: 16px;
  top: 12px;
}
.authDownloadButton--29TfG {
  display: block;
  margin: 0px auto;
}
.authEmployeeText--3yizJ {
  max-width: 280px;
  font-size: 0.9375rem;
  color: #fff;
  text-align: center;
  margin: 0px auto 48px auto;
}
.authSplitHeaderText--3w7SO {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin: 48px auto;
}
.authInputHeaderText--2izpV {
  font-size: 1.125rem;
  color: #212121;
  text-align: center;
  font-weight: bold;
}
.authSplitFormContent--3iqq5 {
  max-width: 350px;
  margin: 0px auto;
}
.authSuccessBadge--3bVKn {
  width: 148px;
  height: 148px;
  border-radius: 79px;
  background: #afd683;
  margin: 0px auto;
  -webkit-animation: badgeAnimation--3-W-L 0.7s;
          animation: badgeAnimation--3-W-L 0.7s;
  position: relative;
}
.authSuccessBadgeIcon--1Uqeu {
  left: 34px;
  right: 34px;
  top: 50%;
  display: block;
  position: absolute;
  height: auto;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.authSuccessText--Y0WGB {
  text-align: center;
  font-size: 1.125rem;
  font-weight: bold;
}
.authSuccessWrapper--JZ0vp {
  -webkit-animation: fadeIn--14nwm 0.7s;
          animation: fadeIn--14nwm 0.7s;
}
.authHeaderName--oodDA {
  font-size: 34px;
  font-weght: bold;
  color: #8f93c4;
  text-align: center;
  margin: 64px 0px;
}
.authHeaderNameWhite--1pM6c {
  font-size: 34px;
  font-weght: bold;
  color: #8f93c4;
  text-align: center;
}
@media (max-height: 900px) {
  .authSplitHeaderText--3w7SO {
    font-size: 1.625rem;
    margin: 32px auto;
  }
  .authInputHeaderText--2izpV {
    font-size: 0.9375rem;
  }
  .authInputWrapper--2pfE5 {
    height: 48px;
  }
  .authInputTitle--372Km {
    top: 6px;
  }
  .authInput--QYFPT {
    padding: 12px 16px 0px 16px;
    font-size: 1.0625rem;
  }
}
@media (max-height: 670px) {
  .authSplitHeaderText--3w7SO {
    font-size: 1.375rem;
    margin: 24px auto;
  }
  .authInputHeaderText--2izpV {
    font-size: 0.875rem;
  }
}
@-webkit-keyframes fadeIn--14nwm {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn--14nwm {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes badgeAnimation--3-W-L {
  0% {
    -webkit-transform: scale(0.5, 0.5);
            transform: scale(0.5, 0.5);
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes badgeAnimation--3-W-L {
  0% {
    -webkit-transform: scale(0.5, 0.5);
            transform: scale(0.5, 0.5);
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
