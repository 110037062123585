.dropdown--3ltuO {
  display: inline-block;
  position: relative;
  height: 3.4375rem;
}
.dropdown--3ltuO.dropdownOpen--235Oa {
  z-index: 100;
}
.dropdownBox--2-7fE {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  border: 1px solid #e2e5f7;
  border-radius: 3px;
  background: #fff;
  color: #8f93c4;
}
.dropdownOpen--235Oa .dropdownBox--2-7fE {
  -webkit-box-shadow: 0 2px 14px 1px #e2e5f7;
          box-shadow: 0 2px 14px 1px #e2e5f7;
}
.dropdownLabel--3kqt7 {
  padding-right: 22px !important;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 39px 0 18px;
  border: none;
  line-height: 1.375rem;
  white-space: nowrap;
  text-align: left;
  font-size: 1rem;
  background: rgba(226,229,247,0.25);
  color: #212121;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  border-radius: 3px;
}
.dropdownLabel--3kqt7:active,
.dropdownOpen--235Oa .dropdownLabel--3kqt7 {
  outline: none;
}
.dropdownPlaceholder--XGV6s {
  color: #8f93c4;
}
.dropdownArrow--1gIpc {
  position: absolute;
  top: 50%;
  right: 21px;
  margin-top: -2px;
}
.dropdownContent--1IJvz {
  position: relative;
  visibility: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-height: 50vh;
  width: 100%;
  height: 0;
  border-top: 1px solid #e2e5f7;
  border-radius: 3px;
  background: #fff;
  color: #212121;
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.dropdownOpen--235Oa .dropdownContent--1IJvz {
  visibility: visible;
  height: auto;
  -webkit-box-shadow: 0 2px 14px 1px #e2e5f7;
          box-shadow: 0 2px 14px 1px #e2e5f7;
}
.dropdownList--3ze4N {
  width: 100%;
  margin: 0;
  padding: 0px 0 0px;
  list-style: none;
}
.dropdownItem--1LJ7J {
  width: 100%;
  list-style: none;
}
.dropdownItemButton--1YDzW {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  line-height: 1.333333333333333;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  vertical-align: top;
  text-align: left;
  font-size: 0.9375rem;
  cursor: pointer;
}
.dropdownItemButton--1YDzW:focus {
  background: #f8f8f8;
}
@media only screen and (hover: hover) {
  .dropdownItemButton--1YDzW:hover {
    background: #f8f8f8;
  }
}
.dropdownItemButtonContent--3Ki2P {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 15px;
}
.dropdownItemIcon--1n6US {
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 22px;
  margin-right: 14px;
}
.dropdownItemIcon--1n6US > * {
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
}
.dropdownItemContent--2cj19 {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.dropdownWithTitle--1EGKi .dropdownLabel--3kqt7 {
  padding-top: 16px;
  padding-left: 16px;
}
.dropdownTitle--3t6s4 {
  font-size: 11px;
  position: absolute;
  z-index: 1;
  color: rgba(158,158,158,1);
  top: 7px;
  margin-top: 0px;
  left: 16px;
}
