.authLayout--83ix- {
  width: 100%;
  height: 100%;
  background: #8f93c4;
}
.authLayoutWhite--3ZjWz {
  width: 100%;
  height: 100%;
  background: #fff;
}
