.settingsView--15tsT {
  padding: 0px 16px;
}
.settingsTitle--bDP9f {
  font-size: 2.1875rem;
  font-weight: normal;
  margin-bottom: 0px;
}
.settingsSeparator--1kUpg {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e2e5f7;
  margin: 42px 0 16px 0px;
  padding: 0;
}
.settingsMainBox--jOlVy {
  margin: 30px auto 30px auto;
  max-width: 1000px;
  border: 1px solid #e2e5f7;
  min-height: 500px;
}
.settingsMenuWrapper--3yLz3 {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 72px;
}
.settingsContent--2cbud {
  margin: 0px;
}
.settingsMenuItem--ZZdC_ {
  display: inline-block;
  width: 20%;
  text-align: center;
  color: #8f93c4;
  font-weight: 500;
  font-size: 1.0625rem;
  line-height: 72px;
  position: relative;
  border-right: 1px solid #e2e5f7;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-bottom: 1px solid #e2e5f7;
  cursor: pointer;
  min-height: 73px;
}
.companyDropZone--3bJkD {
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  cursor: pointer;
}
.settingsMenuItem--ZZdC_:last-child {
  border-right: 0px;
}
.settingsMenuItem--ZZdC_.active--llUNN {
  font-weight: bold;
}
.settingsMenuItem--ZZdC_.active--llUNN::before {
  display: block;
  content: '';
  border-bottom: 4px solid #8f93c4;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.settingsContentGrid--15V-P {
  display: block;
  line-height: 0px;
}
.settingsGridLeft--4en1A,
.settingsGridRight--ERurP {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: normal;
}
.settingsContentGrid--15V-P {
  padding: 48px;
}
.inputErrorActive--2Rl3V {
  display: block;
  margin-top: 5px;
}
.settingsGridLeft--4en1A {
  padding-right: 10px;
}
.settingsGridRight--ERurP {
  padding-left: 10px;
}
.companyDetailsImageWrapper--2H5FE {
  width: 150px;
  height: 150px;
  border-radius: 75px;
  overflow: hidden;
  margin: 0px auto;
  position: relative;
}
.companyDetailsImage--vmMDE {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.companyDetailsEditLabel--iynXT {
  position: absolute;
  width: 100%;
  top: 50%;
  color: #fff;
  font-size: 0.9375rem;
  text-align: center;
  font-weight: 500;
  z-index: 2;
}
.companyDetailsImageFade--3wggt {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.4;
  z-index: 1;
}
.companyDetailsImageText--2Zz1z {
  line-height: normal;
  font-size: 0.8125rem;
  color: rgba(158,158,158,1);
  text-align: center;
  max-width: 280px;
  margin: 0px auto;
  margin-top: 24px;
}
.settingsSubTitle--3-KTT {
  color: #212121;
  font-size: 0.9375rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 12px;
}
.settingsCodeCopiedText--1raS9 {
  color: #8f93c4;
  font-size: 0.8125rem;
  font-weight: normal;
  text-transform: capitalize;
}
.settingsOwnerItem--1z3Qj {
  border-bottom: 0px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.settingsOwnerItem--1z3Qj .employeeStatus--22_D9 {
  display: none !important;
}
.textInputWithLanguage--3s3pm {
  position: relative;
  margin-top: 12px;
}
.textInputWithLanguage--3s3pm.hidden--3sNg8 {
  display: none;
}
.textInputLanguage--26cuc {
  position: absolute;
  right: 20px;
  line-height: 55px;
  color: rgba(158,158,158,1);
  font-size: 0.8125rem;
}
.settingsInput--LNomq {
  width: 100%;
  position: relative;
  z-index: 1;
  background: transparent;
}
.topMargin24--2DPCn {
  margin-top: 24px;
}
.companyDetailsLanguages--FY1R1 {
  height: 55px;
  width: 100%;
  margin-top: 8px;
  line-height: 55px;
}
.languageCheckBox--3VN63 {
  margin-right: 54px;
  color: #212121;
  font-size: 1.0625rem;
  cursor: pointer;
}
.languageCheckBox--3VN63 .checkbox--2DtYy {
  width: 18px;
  height: 18px;
  top: 3px;
}
.languageCheckBox--3VN63.disabled--oRe2q {
  cursor: default;
}
.settingsShareCode--1mtXW {
  color: #8f93c4;
  display: inline-block;
  margin-top: 8px;
}
.settingsFooter--hrixa {
  height: 60px;
  border-top: 1px solid #e2e5f7;
}
.settingsSaveButton--1xtME {
  width: auto;
  height: 40px;
  border-radius: 3px;
  background-color: #8f93c4;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border: none;
  outline: none;
  float: right;
  padding: 0px 12px;
  margin: 10px 20px;
  min-width: 90px;
}
.settingsSaveButton--1xtME[disabled] {
  background: #e2e5f7;
  color: rgba(158,158,158,1);
  cursor: default;
}
.codeInput--3wyVq {
  cursor: pointer;
}
.settingsTeamsView--1kGna {
  padding: 40px;
}
.settingsAddNewHeader--2wMpQ {
  height: 42px;
  margin-bottom: 16px;
}
.settingsAddNewButton--V9W1H {
  width: auto;
  height: 40px;
  border-radius: 3px;
  background-color: #8f93c4;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border: none;
  outline: none;
  float: right;
  padding: 0px 24px;
}
.settingsTeamBox--3D9BV {
  border: 1px solid #e2e5f7;
  min-height: 400px;
}
.settingsTeamGrid--3012I {
  line-height: 0px;
}
.settingsTeamGrid--3012I.headerBox--2RWvF {
  border-bottom: 1px solid #e2e5f7;
}
.settingsLeftGrid--1gcWc {
  vertical-align: top;
  line-height: normal;
  width: 18%;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.settingsRightGrid--2IUBx {
  vertical-align: top;
  line-height: normal;
  width: 82%;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-left: 38px;
}
.settingsRightGrid--2IUBx.teamContent--OtiWK {
  padding: 24px 24px 48px 24px;
  border-left: 1px solid #e2e5f7;
}
.settingsFieldSet--2I5tP {
  border: 0px;
  padding: 0px;
  margin: 0px;
}
.settingsFieldSet--2I5tP[disabled] * {
  cursor: default !important;
}
.settingsHeaderSubtitle---0h3K {
  margin: 0px;
  padding-left: 20px;
  line-height: 55px;
  font-weight: bold;
  font-size: 0.9375rem;
}
.settingsRightGrid--2IUBx .settingsHeaderSubtitle---0h3K {
  padding-left: 0px;
}
.settingsTeamListItem--2dLh- {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 55px;
  line-height: 1.4;
  padding-left: 20px;
  cursor: pointer;
}
.settingsTeamListItemName--99Qji {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}
.settingsListItemDisclosure--ZkVw1 {
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  margin: 0px 16px 0px 5px;
  visibility: hidden;
}
.settingsTeamListItem--2dLh-.active--llUNN {
  background: #f4f4f4;
  font-weight: bold;
  cursor: default;
}
.settingsTeamListItem--2dLh-.active--llUNN .settingsListItemDisclosure--ZkVw1 {
  visibility: visible;
}
.settingsTeamsContentGrid--2zeST {
  display: inline-block;
  line-height: normal;
  width: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0px 10px;
  vertical-align: top;
}
.settingsTeamsContentGrid--2zeST.hidden--3sNg8 {
  display: none;
}
.teamContent--OtiWK .settingsSubTitle--3-KTT {
  margin-left: 10px;
  margin-right: 10px;
}
.teamContent--OtiWK .textInputWithLanguage--3s3pm {
  margin-top: 0px;
}
.positionsList--2GEzZ {
  margin-left: 8px;
  margin-right: 8px;
}
.teamSubTitle--1BSpn {
  margin-top: 40px;
  border-bottom: 1px solid #e2e5f7;
  padding-bottom: 16px;
}
.settingsRuleItem--3vBoc {
  display: inline-block;
  width: 50%;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: normal;
  vertical-align: top;
}
.settingsRuleItem--3vBoc.left--3VPNG {
  padding-left: 10px;
  padding-right: 21px;
}
.settingsRuleItem--3vBoc.right--3xlft {
  padding-right: 10px;
  padding-left: 21px;
}
.disabled--oRe2q {
  opacity: 0.6;
}
.settingsRuleItemCompany--10ATo {
  width: 100%;
}
.settingsRuleItemCompany--10ATo .settingsSwitch--2ME6C {
  top: 15px;
}
.ruleHeaderText--B5bA3 {
  font-size: 1.0625rem;
  color: #212121;
  padding-right: 80px;
}
.ruleOtherText--OS3Wu {
  font-size: 0.8125rem;
  color: rgba(158,158,158,1);
  margin-top: 14px;
}
.settingsSwitch--2ME6C {
  position: absolute;
  right: 10px;
  top: 20px;
  width: 50px;
}
.settingsRadio--1r-ni {
  margin-top: 16px;
}
.hoursInput--h_HuM {
  display: inline-block;
  max-width: 70px;
}
.hoursText--29_n4 {
  display: inline-block;
  line-height: 55px;
  font-size: 0.9375rem;
  color: #212121;
  margin-left: 16px;
}
.settingsTeamGrid--3012I .settingsFooter--hrixa {
  margin-left: 18%;
  border-left: 1px solid #e2e5f7;
}
.deleteButton--3AHAs {
  width: auto;
  height: 40px;
  border-radius: 3px;
  background-color: transparent;
  font-size: 1rem;
  font-weight: 500;
  color: #d0021b;
  text-align: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 10px 20px;
}
.settingsPositionsView--3-EiA,
.settingsLocationsView--1wv_O {
  padding: 40px;
}
.settingsTable--rMTNO {
  width: 100%;
  margin: 0;
  border: 1px solid #e2e5f7;
  border-collapse: separate;
  border-spacing: 0;
}
.settingsPositionItem--2CqWf {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #e2e5f7;
}
.settingsPositionItem--2CqWf:last-child {
  border-bottom: 0px;
}
.settingsPositionColor--1yjDb {
  vertical-align: middle;
  width: 1px;
  padding: 18px 12px 18px 20px;
}
.settingsPositionColorSquare--3cCdI {
  vertical-align: middle;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url(assets/legendMask-e8dec5.png);
  background-size: 24px 24px;
}
.settingsPositionName--2UQCR {
  vertical-align: middle;
}
.settingsEditItemButton--1sfTU {
  vertical-align: middle;
  min-width: 5rem;
  min-height: 2.5rem;
  padding: 0 15px;
  border-radius: 3px;
  font-size: 1rem;
}
.settingsListDeleteButton--oAQ7F {
  vertical-align: middle;
  width: 54px;
  min-height: 2.5rem;
  padding: 0;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: transparent;
  cursor: pointer;
}
.settingsTableHeader--1AmRu {
  vertical-align: middle;
  height: 60px;
  padding: 0 0 0 24px;
  text-align: left;
  font-weight: bold;
  font-size: 1rem;
  color: #212121;
}
.settingsLocationName--3d5mN,
.settingsLocationAddress--1jnO_,
.settingsPositionColor--1yjDb,
.settingsPositionName--2UQCR,
.settingsTableActions--5LEnZ {
  border-top: 1px solid #e2e5f7;
}
.settingsTableActions--5LEnZ {
  width: 1px;
  padding: 0 4px;
  vertical-align: middle;
  white-space: nowrap;
}
.settingsLocationName--3d5mN {
  vertical-align: middle;
  width: 28%;
  height: 60px;
  padding: 0 0 0 24px;
  font-size: 1rem;
}
.settingsLocationAddress--1jnO_ {
  vertical-align: middle;
  height: 60px;
  padding: 0 0 0 24px;
  color: #757575;
  font-size: 0.9375rem;
}
.settingsRolesView--uiWrJ {
  padding: 40px;
}
.rolesBlockTitle--2PmiI {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.9375rem;
  font-weight: 500;
  margin-top: 20px;
}
.roleExplanation--3KdsL {
  font-size: 0.8125rem;
  color: rgba(158,158,158,1);
}
.roleSecondBlock--3Qhie {
  margin-top: 40px;
}
.employeeRoleHeader--M8MAs,
.employeeRoleName--3MNdB,
.employeeRoleRole--2CDXT {
  vertical-align: middle;
  height: 60px;
  padding: 0 0 0 16px;
  border-bottom: 1px solid #e2e5f7;
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
}
.employeeRoleName--3MNdB {
  width: 32%;
}
.employeeRoleRole--2CDXT {
  width: 100%;
}
.envelopIcon--3jFei {
  margin-right: 8px;
  height: 30px;
}
.hoursTextField--WJ9i- {
  line-height: normal;
}
.settingsSendText--3FFyD {
  vertical-align: top;
  line-height: 30px;
}
.copyToClipboardText--1XNmi {
  font-weight: bold;
  color: #8f93c4;
  margin-top: 4px;
  margin-bottom: 24px;
  cursor: pointer;
  display: block;
}
.settingsBottomSaveButton--3eo4Z {
  position: fixed !important;
  right: 0px;
  left: 0px;
  width: 100%;
  z-index: 100;
}
.settingsTimeZoneWrapper--16m_K {
  margin-top: 8px;
  color: #949ca9;
  font-size: 0.75rem;
  text-align: center;
}
.settingsDropDown--3l4tf {
  display: inline-block;
  border: 0px;
  background: transparent;
}
.settingsDropDownBox--vhhK9 {
  margin: 0px;
  width: auto;
  height: 32px;
  border: 0px;
  margin-left: 8px;
  background: transparent !important;
  border: 0px !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.settingsDropDownBox--vhhK9 .dropDownLabel {
  font-size: 0.75rem;
  padding: 0px 38px 0px 0px;
  color: #8f93c4;
}
.settingsDropDownContent--1wvUV {
  margin: 0px;
  top: 110px !important;
  border-radius: 8px;
  width: 316px !important;
  max-height: 350px !important;
}
.settingsDropDownContent--1wvUV li span {
  font-size: 0.8125rem;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow: hidden;
  width: 300px;
  white-space: nowrap;
  display: block;
}
.settingsSelectorItems--1mw8W {
  padding: 0px 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.settingsCompanyDropDown--2-AHm {
  margin: 12px 0px 0px 0px;
  width: 100%;
}
.settingsYes--10ydl {
  color: #8f93c4;
}
.settingsNo--1B90e {
  color: #949ca9;
}
.settingsFixedUpdateBar--1bYJt {
  bottom: 0;
  width: 100%;
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  left: 80px;
  z-index: 10;
  border-top: 1px solid #e2e5f7;
}
.settingsFixedUpdateBar--1bYJt span {
  margin-left: auto;
  margin-right: 160px;
}
.teamSettingFormIcon--UEhmm {
  opacity: 1;
  color: #b9c7d3;
  fill: #b9c7d3;
}
.teamSettingFormIcon--UEhmm * {
  color: #b9c7d3;
  fill: #b9c7d3;
}
.teamSettingInputItemInput--r432v {
  display: table-cell;
  height: 42px;
  position: relative;
}
.teamSettingFormIcon--UEhmm {
  opacity: 1;
  color: #b9c7d3;
  fill: #b9c7d3;
}
.teamSettingFormIcon--UEhmm * {
  color: #b9c7d3;
  fill: #b9c7d3;
}
.teamSettingTimeZoneWrapper--Tv98P {
  height: 16px;
  margin: 16px 0px 8px;
}
.teamSettingTimeZoneButton--BK_vk {
  background: transparent;
  border: 0px;
  padding: 0px;
  margin: 0px;
  text-align: right;
  float: left;
  color: #8f93c4;
  font-size: 0.75rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  cursor: default;
}
.teamSettingTimeZoneButton--BK_vk svg {
  margin: 2px 8px 2px;
}
.teamSettingDropDownLabel--1DgFd {
  font-size: 1rem !important;
}
.teamSettingInputItem--9sBzJ {
  display: table;
  width: 100%;
  margin-top: 10px;
  padding: 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.teamSettingInputItem--9sBzJ:first-child {
  margin-top: 0px;
}
.teamSettingInputItemIcon--1uXHJ {
  display: table-cell;
  width: 36px;
  vertical-align: middle;
}
.teamSettingInputBox--GFJ2F {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  width: 100%;
  background: rgba(226,229,247,0.25);
}
.teamSettingInputBox--GFJ2F input:first-child {
  height: 50px;
  border: 0px;
  padding: 22px 8px 8px 10px;
  background: transparent;
  font-size: 1rem;
}
.teamSettingDropDownBox--17C-z {
  position: absolute;
  border: 1px solid #e2e5f7;
  border-radius: 3px;
}
.teamSettingDropDownBox--17C-z button {
  font-size: 0.875rem;
  padding: 0 1rem 0 8px;
}
