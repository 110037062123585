.popover--2LceR {
  position: fixed;
  width: 0;
  height: 0;
  z-index: 100;
}
.popoverFrame--fTo6G {
  position: absolute;
  -webkit-box-shadow: 0 11px 20px 1px rgba(0,0,0,0.38);
          box-shadow: 0 11px 20px 1px rgba(0,0,0,0.38);
  border-radius: 8px;
}
.popoverFrameTopArrow--3ZPCJ:before,
.popoverFrameRightArrow--1wskE:before,
.popoverFrameBottomArrow--1VUj9:before,
.popoverFrameLeftArrow--1u5Iv:before {
  content: ' ';
  position: absolute;
  width: 13px;
  height: 13px;
  background: #fff;
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}
.popoverFrameTopArrow--3ZPCJ {
  top: 9px;
}
.popoverFrameTopArrow--3ZPCJ:before {
  top: -6px;
  border-top-left-radius: 8px;
}
.popoverFrameLeftArrow--1u5Iv {
  left: 9px;
}
.popoverFrameLeftArrow--1u5Iv:before {
  left: -6px;
}
.popoverFrameBottomArrow--1VUj9 {
  bottom: 9px;
}
.popoverFrameBottomArrow--1VUj9:before {
  bottom: -6px;
}
.popoverFrameRightArrow--1wskE {
  right: 9px;
}
.popoverFrameRightArrow--1wskE:before {
  right: -6px;
}
.popoverFrameTopArrow--3ZPCJ.popoverFrameStartArrow--1goR7,
.popoverFrameBottomArrow--1VUj9.popoverFrameStartArrow--1goR7 {
  left: -24px;
}
.popoverFrameTopArrow--3ZPCJ.popoverFrameStartArrow--1goR7:before,
.popoverFrameBottomArrow--1VUj9.popoverFrameStartArrow--1goR7:before {
  left: 50px;
}
.popoverFrameTopArrow--3ZPCJ.popoverFrameCenterArrow--1bVhV,
.popoverFrameBottomArrow--1VUj9.popoverFrameCenterArrow--1bVhV {
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
.popoverFrameTopArrow--3ZPCJ.popoverFrameCenterArrow--1bVhV:before,
.popoverFrameBottomArrow--1VUj9.popoverFrameCenterArrow--1bVhV:before {
  left: 50%;
}
.popoverFrameTopArrow--3ZPCJ.popoverFrameCenterArrow--1bVhV:before,
.popoverFrameBottomArrow--1VUj9.popoverFrameCenterArrow--1bVhV:before {
  left: -webkit-calc(50% - 6px);
  left: calc(50% - 6px);
}
.popoverFrameTopArrow--3ZPCJ.popoverFrameEndArrow--jV6B_,
.popoverFrameBottomArrow--1VUj9.popoverFrameEndArrow--jV6B_ {
  right: -24px;
}
.popoverFrameTopArrow--3ZPCJ.popoverFrameEndArrow--jV6B_:before,
.popoverFrameBottomArrow--1VUj9.popoverFrameEndArrow--jV6B_:before {
  right: 50px;
}
.popoverFrameLeftArrow--1u5Iv.popoverFrameStartArrow--1goR7,
.popoverFrameRightArrow--1wskE.popoverFrameStartArrow--1goR7 {
  top: -24px;
}
.popoverFrameLeftArrow--1u5Iv.popoverFrameStartArrow--1goR7:before,
.popoverFrameRightArrow--1wskE.popoverFrameStartArrow--1goR7:before {
  top: 50px;
}
.popoverFrameLeftArrow--1u5Iv.popoverFrameCenterArrow--1bVhV,
.popoverFrameRightArrow--1wskE.popoverFrameCenterArrow--1bVhV {
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.popoverFrameLeftArrow--1u5Iv.popoverFrameCenterArrow--1bVhV:before,
.popoverFrameRightArrow--1wskE.popoverFrameCenterArrow--1bVhV:before {
  top: 50%;
}
.popoverFrameLeftArrow--1u5Iv.popoverFrameCenterArrow--1bVhV:before,
.popoverFrameRightArrow--1wskE.popoverFrameCenterArrow--1bVhV:before {
  top: -webkit-calc(50% - 6px);
  top: calc(50% - 6px);
}
.popoverFrameLeftArrow--1u5Iv.popoverFrameEndArrow--jV6B_,
.popoverFrameRightArrow--1wskE.popoverFrameEndArrow--jV6B_ {
  bottom: -24px;
}
.popoverFrameLeftArrow--1u5Iv.popoverFrameEndArrow--jV6B_:before,
.popoverFrameRightArrow--1wskE.popoverFrameEndArrow--jV6B_:before {
  bottom: 18px;
}
.popoverBox--2WAHo {
  position: relative;
  width: 293px;
  background: #fff;
}
