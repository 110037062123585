.adminsView--3budc {
  padding: 0px 16px;
}
.adminsTitle--1FwLZ {
  font-size: 2.1875rem;
  font-weight: normal;
  margin-bottom: 0px;
}
.adminsSeparator--3wso2 {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e2e5f7;
  margin: 42px 0 16px 0px;
  padding: 0;
}
.adminsMainBox--RsRvm {
  margin: 30px auto 30px auto;
  max-width: 1000px;
  border: 1px solid #e2e5f7;
  min-height: 500px;
}
.adminsUserList--2K8cX {
  margin: 0px;
}
.adminsHeaderActions--MHozp {
  min-height: 38px;
  margin: 0px auto;
  margin-bottom: -12px;
  max-width: 1000px;
  text-align: right;
}
.adminsHeaderActions--MHozp .addButton--3808Y {
  min-height: 38px;
  padding: 0px 16px;
  font-size: 0.875rem;
  min-width: 140px;
}
.adminsHeader--2rNCz {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e2e5f7;
  text-transform: uppercase;
  font-size: 0.6875rem;
  color: rgba(84,84,84,1);
}
.adminsHeader--2rNCz .email--3ISTT {
  padding: 4px 16px;
  width: 20%;
  border-right: 1px solid #e2e5f7;
}
.adminsHeader--2rNCz .firstName--kOLc4,
.adminsHeader--2rNCz .lastName--3RcHk {
  padding: 4px 16px;
  width: 20%;
  border-right: 1px solid #e2e5f7;
}
.adminsHeader--2rNCz .action--1iMJh {
  padding: 4px 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.adminsAddView--3g9Kz {
  max-width: 380px;
  min-height: 300px;
  margin: 16px auto;
  border-radius: 3px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #fff;
  -webkit-box-shadow: 0 5px 15px 0 rgba(0,0,0,0.38);
          box-shadow: 0 5px 15px 0 rgba(0,0,0,0.38);
  padding-bottom: 24px;
  padding: 24px;
}
.adminsAddView--3g9Kz textarea {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 180px;
  border: 1px solid #e2e5f7;
  border-radius: 5px;
  margin: 24px 0px;
  resize: none;
  padding: 16px;
  font-size: 1rem;
}
.addDialogContent--1Pfxn {
  padding: 16px;
}
.addInputWrapper--3H7QC {
  position: relative;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #e2e5f7;
  background: rgba(226,229,247,0.25);
  margin-top: 16px;
}
.addInputWrapper--3H7QC:first-child {
  margin-top: 0px;
}
.addInputTitle--zbGx9 {
  font-size: 11px;
  position: absolute;
  z-index: 0;
  color: rgba(158,158,158,1);
  top: 10px;
  left: 16px;
}
.adminsModalCancel--1FGYF {
  background: #d4d4d5;
  color: #333;
}
.addInputError--2WyTh {
  margin-top: 8px;
}
.addInput--3N2LM {
  width: 100%;
  margin-bottom: 12px;
  background: transparent;
  border: 0px;
  position: relative;
  padding: 12px 16px 0px 16px;
  font-size: 1.0625rem;
}
.adminsModalButton--1dTQU {
  margin-top: 16px;
}
.adminsHeaderText--1srEe {
  font-size: 1.1875rem;
  color: #212121;
  font-weight: bold;
  text-align: center;
  margin: 0px auto;
  max-width: 260px;
}
.error--2h3rv {
  color: #d0021b;
  font-size: 0.75rem;
  margin: 0px;
  margin-top: -12px;
}
