.authModalBox--26NVl {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 450px;
  margin: 0 auto;
  border-radius: 3px;
}
.authModalTop--aVN7z {
  padding: 41px 42px 14px;
  text-align: center;
  position: relative;
}
.authModalSlogan--3pcRg {
  color: #626262;
  font-size: 1.125rem;
  font-weight: bold;
}
.authModalLangagePicker--2nLNa {
  position: fixed;
  top: 0px;
  right: 0px;
  color: #8f93c4;
}
