.feedView--21XZ- {
  padding: 0px 16px;
}
.feedTitle--1fKmM {
  font-size: 2.1875rem;
  font-weight: normal;
  margin-bottom: 0px;
}
.feedSeparator--2ic66 {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e2e5f7;
  margin: 42px 0 16px 0px;
  padding: 0;
}
.feedMainBox--13FvV {
  margin: 30px auto 30px auto;
  max-width: 1000px;
  border: 1px solid #e2e5f7;
  min-height: 500px;
}
.feedList--33-Qg {
  margin: 0px;
}
.feedHeaderActions--1BGg1 {
  min-height: 38px;
  margin: 0px auto;
  margin-bottom: -12px;
  max-width: 1000px;
  text-align: right;
}
.feedHeaderActions--1BGg1 .addButton--2wL8R {
  min-height: 38px;
  padding: 0px 16px;
  font-size: 0.875rem;
  min-width: 140px;
}
