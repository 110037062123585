.modal--1JDxe {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: rgba(0,0,0,0.5);
}
.modal--static--vN_3o {
  position: static;
  z-index: auto;
  background: none;
}
.modalTable--2RWQi {
  display: table;
  width: 100%;
  height: 100%;
}
.modalCell--3w3gR {
  display: table-cell;
  vertical-align: top;
  padding: 73px 10px 10px 10px;
}
.modal--centered--10xrG .modalCell--3w3gR {
  vertical-align: middle;
}
.modalFullCellHeight--3yCmI {
  padding: 24px 10px;
}
.modalTitleBar--26TwL {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background: #8f93c4;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.modalTitleCloseButton--1bMkd {
  margin: 14px;
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
  padding: 0;
  border: none;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  text-align: center;
  cursor: pointer;
}
.modalTitleCloseIcon--2IgZb {
  display: block;
  margin: 0 auto;
}
.modalTitleBarText--2mcKE {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0;
      -ms-flex: 1 1 0px;
          flex: 1 1 0;
  margin: 0;
  padding: 14px;
  line-height: 1em;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
}
.modalTitleActionsButton--dzviF {
  -webkit-box-flex: 0;
  -webkit-flex: none;
      -ms-flex: none;
          flex: none;
  width: 65px;
}
