.documentView--WZD7b {
  padding: 0 16px;
}
.documentTitle--WIi_s {
  font-size: 2.1875rem;
  font-weight: normal;
  margin-bottom: 0px;
}
.documentSeparator--3SGlN {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e2e5f7;
  margin: 42px 0 16px 0px;
  padding: 0;
}
.documentMainBox--2TmLF {
  margin: 30px auto 30px auto;
  max-width: 1000px;
  border: 1px solid #e2e5f7;
  min-height: 500px;
}
.documentList--bY0gS {
  margin: 0;
}
.documentHeaderActions--lKaKn {
  min-height: 38px;
  margin: 0 auto;
  margin-bottom: -12px;
  max-width: 1000px;
  text-align: right;
}
.documentHeaderActions--lKaKn .addButton--Ulq8P {
  min-height: 38px;
  padding: 0 16px;
  font-size: 0.875rem;
  min-width: 140px;
}
