.customersView--1cMWb {
  padding: 0 16px;
}
.customersTitle--3zLwx {
  font-size: 2.1875rem;
  font-weight: normal;
  margin-bottom: 0;
}
.customersSeparator--3802l {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e2e5f7;
  margin: 42px 0 16px 0px;
  padding: 0;
}
.customersMainBox--3se-q {
  margin: 30px auto 30px auto;
  max-width: 1000px;
  min-height: 500px;
}
.customersHeaderActions--37qkf {
  min-height: 38px;
  margin: 0 auto;
  margin-bottom: -12px;
  max-width: 1000px;
  text-align: right;
}
.customersHeaderActions--37qkf .addButton--1wi4b {
  min-height: 38px;
  padding: 0px 16px;
  font-size: 0.875rem;
  min-width: 140px;
}
.customersList--14tl3 {
  margin: 0;
}
.customersAddView--1nSqv {
  max-width: 380px;
  min-height: 300px;
  margin: 16px auto;
  border-radius: 3px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #fff;
  -webkit-box-shadow: 0 5px 15px 0 rgba(0,0,0,0.38);
          box-shadow: 0 5px 15px 0 rgba(0,0,0,0.38);
  padding-bottom: 24px;
  padding: 24px;
}
.customersAddView--1nSqv textarea {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 180px;
  border: 1px solid #e2e5f7;
  border-radius: 5px;
  margin: 24px 0px;
  resize: none;
  padding: 16px;
  font-size: 1rem;
}
.addDialogContent--3QOXc {
  padding: 16px;
}
.addInputWrapper--FCAZA {
  position: relative;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #e2e5f7;
  background: rgba(226,229,247,0.25);
  margin-top: 16px;
}
.addInputWrapper--FCAZA:first-child {
  margin-top: 0px;
}
.addInputTitle--3bJqH {
  font-size: 11px;
  position: absolute;
  z-index: 0;
  color: rgba(158,158,158,1);
  top: 10px;
  left: 16px;
}
.adminsModalCancel--10QEH {
  background: #d4d4d5;
  color: #333;
}
.addInputError--2KDmF {
  margin-top: 8px;
}
.addInput--6mfxi {
  width: 100%;
  margin-bottom: 12px;
  background: transparent;
  border: 0px;
  position: relative;
  padding: 12px 16px 0px 16px;
  font-size: 1.0625rem;
}
.adminsModalButton--1RPkA {
  margin-top: 16px;
}
.adminsHeaderText--1EfUi {
  font-size: 1.1875rem;
  color: #212121;
  font-weight: bold;
  text-align: center;
  margin: 0px auto;
  max-width: 260px;
}
.error--1UTzH {
  color: #d0021b;
  font-size: 0.75rem;
  margin: 0px;
  margin-top: -12px;
}
.dropdown--1DaHO {
  display: inline-block;
  width: 100%;
  position: relative;
  height: 3.4375rem;
}
.dropdown--1DaHO.dropdownOpen--1Y5WI {
  z-index: 100;
}
.dropdown--1DaHO.small--2iZ1- {
  width: 180px;
  height: 3rem;
  margin-top: 0px;
}
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}
.card--1HKEM {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 16px;
  overflow: hidden;
  cursor: default;
  -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.1);
          box-shadow: 0 0 10px rgba(0,0,0,0.1);
  position: static;
  -webkit-transform: 0.3s all ease-in-out;
      -ms-transform: 0.3s all ease-in-out;
          transform: 0.3s all ease-in-out;
  margin: 0 auto;
  margin-bottom: 24px;
}
.card--1HKEM img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid #fff;
}
.card--1HKEM .profileContainer--1hZxW {
  background-color: #8f93c4;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 12px 40px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.card--1HKEM .profileContainer--1hZxW img {
  width: 120px;
  height: 120px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 60px;
}
.card--1HKEM .profileInfo--lP62- {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  padding: 0px 16px;
}
.card--1HKEM .profileInfo--lP62- h1 {
  font-size: 2rem;
  color: rgba(0,0,0,0.7);
}
.card--1HKEM .profileInfo--lP62- .jobTitle--27Rl- {
  color: rgba(0,0,0,0.5);
  font-weight: 700;
}
.card--1HKEM .profileInfo--lP62- .desc--3NuZA {
  color: rgba(0,0,0,0.9);
  font-size: 0.9rem;
  max-width: 300px;
  margin-top: 8px;
}
.card--1HKEM .profileSocial--1C1Va {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
}
.card--1HKEM .profileSocial--1C1Va a {
  color: #7695ff;
  text-decoration: none;
  font-size: 0.9rem;
}
.card--1HKEM .profileSocial--1C1Va a:hover {
  text-decoration: underline;
}
.cardBottom--15-eq {
  width: 100%;
  background-color: #ff9874;
  height: 5px;
  -webkit-transition: 0.3s all ease-in-out;
  -o-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}
.customersFilters--1Tuwj {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 6px;
  border: 1px solid #e2e5f7;
  margin: 0 auto;
  margin-top: 32px;
  margin-bottom: -12px;
  max-width: 1000px;
  min-height: 24px;
  position: relative;
  padding: 8px;
  gap: 8px;
}
.customersFiltersTitle--1CZXl {
  display: block;
  position: absolute;
  background: #fff;
  color: #2c3134;
  font-size: 0.875rem;
  text-transform: uppercase;
  top: -16px;
  left: 8px;
}
