.claimsView--2uNOr {
  padding: 0px 16px;
}
.claimsTitle--Wv6jM {
  font-size: 2.1875rem;
  font-weight: normal;
  margin-bottom: 0px;
}
.claimsSeparator--1ffAZ {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e2e5f7;
  margin: 42px 0 16px 0px;
  padding: 0;
}
.claimsMainBox--3dcLb {
  margin: 30px auto 30px auto;
  max-width: 1000px;
  border: 1px solid #e2e5f7;
  min-height: 500px;
}
