.customerItem--_3fjA {
  border: 1px solid #e2e5f7;
  border-radius: 4px;
  padding: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.customerItem--_3fjA .email--338iK,
.customerItem--_3fjA .firstName--3T66n,
.customerItem--_3fjA .lastName--3lJgq {
  padding: 4px;
}
.customerItem--_3fjA .action--pyRD0 {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  height: 38px;
}
.deleteButton--2NYG6,
.rejectButton--1R-Bn {
  background: #d0021b;
  border: 0px;
  border-radius: 8px;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
  height: 38px;
  line-height: 36px;
  padding: 0px 12px;
  margin: 8px;
  float: right;
  cursor: pointer;
}
.approveButton--2e2MC {
  background: #5fae08;
  border: 0px;
  border-radius: 8px;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
  height: 38px;
  line-height: 36px;
  padding: 0px 12px;
  margin: 8px;
  float: right;
  cursor: pointer;
}
.customerStatus--2uUPn {
  padding: 2px 4px;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  display: inline-block;
  font-weight: bold;
}
.customerStatus--2uUPn.pending--1-Mg2 {
  background-color: #ff9800;
}
.customerStatus--2uUPn.approved--2NMzk {
  background-color: #5fae08;
}
.customerStatus--2uUPn.rejected--3fDtc {
  background-color: #d0021b;
}
.documentList--2zbY7 {
  margin-bottom: 16px;
  width: 100%;
}
.documentList--2zbY7 .documentItem--1N71U {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #f4f4f5;
  border-radius: 2px;
  padding: 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.documentList--2zbY7 .documentName--2NOYB {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-left: 16px;
  font-weight: 500;
  cursor: pointer;
  color: #00f;
}
.link--iX07r {
  color: #8f93c4;
  text-decoration: underline;
  cursor: pointer;
}
.customerIcon--koE0c {
  width: 120px;
  cursor: pointer;
}
.customerIcon--koE0c img {
  width: 120px;
  height: 120px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 60px;
  border: 10px solid #fff;
}
.contentBox--3mBDi {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.subTitle--2_16z {
  font-size: 12px;
  text-transform: uppercase;
}
