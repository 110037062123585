.dashboardView--c1V6G {
  padding: 0px 16px;
}
.dashboardSectionHeader--UBFev {
  font-size: 1.125rem;
  text-transform: uppercase;
  margin-left: 16px;
  font-weight: normal;
}
.dashboardTitle--1k2UF {
  font-size: 2.1875rem;
  font-weight: normal;
}
.dashboardSeparator--d-CD8 {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e2e5f7;
  margin: 16px 0;
  padding: 0;
}
.dashboardTabBar--2pntS {
  max-width: 452px;
  height: 40px;
  border: 1px solid #e2e5f7;
  border-radius: 3px;
  margin: 0px auto;
}
.dashboardTabBar--2pntS .tabItem--2hk7h {
  display: inline-block;
  height: 40px;
  width: 150px;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  font-size: 0.8125rem;
  color: #8f93c4;
  cursor: pointer;
}
.dashboardTabBar--2pntS .tabItem--2hk7h.mid--E_VG5 {
  border-left: 1px solid #e2e5f7;
  border-right: 1px solid #e2e5f7;
}
.dashboardTabBar--2pntS .tabItem--2hk7h.active--3N6Bi {
  background: #8f93c4;
  color: #fff;
}
.dashboardProgress--34RqM {
  max-width: 280px;
  margin: 0px auto;
  margin-top: 42px;
}
.dashboardProgressItem--2UFLV {
  display: inline-block;
  width: 33.3%;
  vertical-align: top;
  text-align: center;
}
.dashboardHoursItem--3kdtN {
  line-height: 45px;
  text-align: center;
  color: #8f93c4;
  font-size: 1.25rem;
}
.dashboardProgressText--OXsM2 {
  font-size: 0.6875rem;
  max-width: 100%;
  margin: 0px auto;
  font-weight: normal;
}
.dashboardCardsWrapper--35ToD {
  line-height: 0px;
  margin-top: 46px;
}
.dashboardHeaderContainer--j9BwW {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.dateSquare--FZ2mO {
  margin: auto 10px auto 0;
}
.dayNotesContainer--1bDvu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: auto 0 auto auto;
  font-size: 0.6875rem;
}
.dayNotesDate--3GmZ3 {
  margin: 4px;
  font-size: 0.9rem;
}
.dashboardMainBox--2dJDT {
  margin: 30px auto 30px auto;
  max-width: 1000px;
  border: 1px solid #e2e5f7;
}
.dashboardMainBox--2dJDT .formUploadWrapper {
  margin: 16px;
}
.addDialogContent--19VJX {
  padding: 16px;
}
.addTextareaWrapper--3F-hE {
  position: relative;
  border-radius: 5px;
  border: 1px solid #e2e5f7;
  background: rgba(226,229,247,0.25);
  margin-top: 16px;
}
.addInputWrapper--2enSY {
  position: relative;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #e2e5f7;
  background: rgba(226,229,247,0.25);
  margin-top: 16px;
}
.addInputWrapper--2enSY:first-child {
  margin-top: 0px;
}
.addInputTitle--lBZeO {
  font-size: 11px;
  position: absolute;
  z-index: 0;
  color: rgba(158,158,158,1);
  top: 10px;
  left: 16px;
}
.adminsModalCancel--1unSq {
  background: #d4d4d5;
  color: #333;
}
.addInputError--2cXdw {
  margin-top: 8px;
}
.addInput--3OKnw {
  width: 100%;
  margin-bottom: 12px;
  background: transparent;
  border: 0px;
  position: relative;
  padding: 12px 16px 0px 16px;
  font-size: 1.0625rem;
}
textarea.addInput--3OKnw {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 180px;
  border: 0px;
  border-radius: 5px;
  margin-top: 8px;
  resize: none;
  padding: 16px;
  font-size: 1rem;
}
.dashboardButton--2du1j {
  margin-top: 16px;
}
.dashboardHeaderText--261Kq {
  font-size: 1.1875rem;
  color: #212121;
  font-weight: bold;
  text-align: center;
  margin: 0px auto;
  max-width: 260px;
}
.documentsHeader--2Zho9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e2e5f7;
  text-transform: uppercase;
  font-size: 0.6875rem;
  color: rgba(84,84,84,1);
}
.documentsHeader--2Zho9 .name--2F8t5 {
  padding: 4px 16px;
  width: 80%;
  border-right: 1px solid #e2e5f7;
}
.documentsHeader--2Zho9 .action--kdSyo {
  padding: 4px 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.documentsList--2b_Tl {
  margin: 0;
}
.documentItem--lZV8l {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e2e5f7;
}
.documentItem--lZV8l .name--2F8t5 {
  padding: 16px;
  width: 80%;
  border-right: 1px solid #e2e5f7;
}
.documentItem--lZV8l .name--2F8t5 span {
  margin-left: 16px;
}
.documentItem--lZV8l .action--kdSyo {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.deleteButton--3HTA3 {
  background: #d0021b;
  border: 0;
  border-radius: 8px;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
  height: 38px;
  line-height: 36px;
  padding: 0 12px;
  margin: 8px;
  float: right;
  cursor: pointer;
}
