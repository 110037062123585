.feedItem--3gBdC {
  margin: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.feedItem--3gBdC img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 160px;
  height: 100px;
  margin: 16px;
}
.feedImage--2dZ9i {
  width: 182px;
  height: 100px;
  background-size: cover;
}
.feedContent--3jvH0 {
  height: 120px;
  font-size: 0.875rem;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 16px;
}
