.faqItem--3g-aI {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e2e5f7;
}
.faqItem--3g-aI .title--yvjfK {
  padding: 16px;
  width: 20%;
  border-right: 1px solid #e2e5f7;
}
.faqItem--3g-aI .content--2-Qra {
  padding: 16px;
  width: 50%;
  border-right: 1px solid #e2e5f7;
  max-height: 90px;
  overflow: hidden;
  position: relative;
}
.faqItem--3g-aI .contentFade--2vZTM {
  height: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0)), to(#fff));
  background-image: -o-linear-gradient(top, rgba(255,255,255,0), #fff);
  background-image: linear-gradient(180deg, rgba(255,255,255,0), #fff);
}
.faqItem--3g-aI .action--1Zzkn {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.faqItem--3g-aI .position--3Us_o {
  border: 1px solid #f4f4f4;
  border-radius: 8px;
  height: 32px;
  width: 42px;
  font-size: 0.875rem;
  text-align: center;
}
.deleteButton--1o2f5,
.updateButton--2P7rq {
  background: #d0021b;
  border: 0px;
  border-radius: 8px;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
  height: 38px;
  line-height: 36px;
  padding: 0px 12px;
  margin: 8px;
  float: right;
  cursor: pointer;
}
.updateButton--2P7rq {
  background: #8f93c4;
}
