.dateSquareContainer--jT25W {
  border: 1px solid #b6bdd9;
  border-radius: 7px;
  padding: 4px;
}
.dateSquareMonth--2RwkB {
  text-align: center;
  color: #a677d9;
}
.dateSquareDay--2vxNd {
  text-align: center;
  color: #000;
}
