.contextMenuButton--KgNUB {
  padding: 0;
  border: none;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  text-align: center;
  cursor: pointer;
}
.contextMenuButtonIcon--2CPgE {
  display: block;
  margin: 0 auto;
}
